// LoginSuccess.tsx

import React from 'react';

const LoginSuccess: React.FC = () => {
  return (
    <div>
      <h1>Welcome to the Login Success Page!</h1>
      {/* Your content for the login success page goes here */}
    </div>
  );
};

export default LoginSuccess;
