import 'firebase/compat/auth';

import React, { useEffect } from 'react';

import firebase from 'firebase/compat/app';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);

const provider = new firebase.auth.GoogleAuthProvider();

const SignIn: React.FC = () => {
  useEffect(() => {
    const handleAuthStateChange = async (user: firebase.User | null) => {
      if (user) {
        console.log('User is signed in: ', user);
        try {
          const token = await getNewIdToken(user.refreshToken);
          localStorage.setItem('idToken', token);
          localStorage.setItem('refreshToken', user.refreshToken);
          console.log('Token Set in localStorage', token);
          console.log('RefreshToken Set in localStorage', user.refreshToken);
        } catch (error) {
          console.error('Error getting new ID token:', error);
        }
      } else {
        console.log('No user is signed in');
      }
    };

    firebase.auth().onAuthStateChanged(handleAuthStateChange);
  }, []);

  const signInWithGoogle = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    try {
      console.log('inmain func');
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;
      if (user) {
        const { displayName: name, email, photoURL: avatar, uid } = user;

        const idToken = await user.getIdToken(true);

        const verifyResponse = await fetch(
          `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/verifyToken`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              idToken: idToken,
              uid: uid,
            }),
          }
        );

        if (!verifyResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const verifyData = await verifyResponse.json();
        if (verifyData.success) {
          console.log('new data:', verifyData);
          const token = verifyData.idToken;
          localStorage.setItem('idToken', token);
          console.log('Set in localStorage');

          const response = await fetch(
            `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/insertUser`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': '69420',
              },
              body: JSON.stringify({
                email: email,
                name: name,
                avatar: avatar,
                id: uid,
              }),
            }
          );

          const hasuraData = await response.json();
          console.log('User added to Hasura:', hasuraData);
          if (hasuraData) {
            window.location.href = '/login_success';
          }
        } else {
          throw new Error('Error creating custom token: ' + verifyData.message);
        }
      }
    } catch (error: any) {
      console.error('Not able to sign in:', error);
    }
  };

  const getNewIdToken: any = async (refreshToken: string) => {
    const url = `https://securetoken.googleapis.com/v1/token?key=${config.apiKey}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    });

    const data = await response.json();
    if (response.ok) {
      console.log('New Token:', data);
      const newIdToken = data.id_token;
      return newIdToken;
    } else {
      console.error('Error refreshing token:', data.error);
      throw new Error(data.error.message);
    }
  };

  return (
    <div>
      <h1>Firebase Auth + Hasura JWT example</h1>
      <button onClick={signInWithGoogle}>Sign in with Google</button>
    </div>
  );
};

export default SignIn;
