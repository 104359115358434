import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import LoginSuccess from './LoginSuccess';
import React from 'react';
import SignIn from './SignIn';

// Define your routes
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route path='/login_success' element={<LoginSuccess />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  );
};

// Render your application
export default App;
